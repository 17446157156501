import React, { ChangeEvent, useEffect, useState } from "react"
import cn from "classnames"
import Container from "@ecom/ui/components/Container"
import Slider from "@material-ui/core/Slider"
import TextField from "@material-ui/core/TextField"

import * as styles from "./newCalculator.module.scss"

interface CalculatorProps {
  darkColor?: boolean
  orderNum?: string
}

export const NewCalculator = ({ darkColor = false, orderNum }: CalculatorProps) => {
  const [card, setCard] = useState<number | number[]>(300)
  const [dk, setDK] = useState<number | number[]>(100)
  const [errorTextCard, setErrorTextCard] = useState("")
  const [errorTextDk, setErrorTextDK] = useState("")
  const [sum, setSum] = useState(0)
  const fixPriceCard = 1500
  const fixPriceDk = 10000
  const minNum = 0
  const maxNum = 3000

  const onChangeSpending = (_: ChangeEvent<{}>, value: number | number[]) => {
    setCard(value)
  }

  const onChangeBalance = (_: ChangeEvent<{}>, value: number | number[]) => {
    setDK(value)
  }

  useEffect(() => {
    if (card < minNum) {
      setErrorTextCard("Введенная сумма меньше мин. значения")
    } else if (card > maxNum) {
      setErrorTextCard("Введенная сумма больше макс. значения")
    } else {
      setErrorTextCard("")
    }
  }, [card])

  useEffect(() => {
    if (dk < minNum) {
      setErrorTextDK("Введенная сумма меньше мин. значения")
    } else if (dk > maxNum) {
      setErrorTextDK("Введенная сумма больше макс. значения")
    } else {
      setErrorTextDK("")
    }
  }, [dk])

  useEffect(() => {
    const sumHalva = +card * fixPriceCard + +dk * fixPriceDk
    if (!errorTextCard && !errorTextDk) {
      setSum(sumHalva)
    }
  }, [card, dk, errorTextCard, errorTextDk])

  function handleOnChangeSumCard(e: { target: { value: any } }) {
    setCard(e.target.value.replace(/[^0-9.]/g, "").slice(0, 5))
  }

  function handleOnChangeSumDk(e: { target: { value: any } }) {
    setDK(e.target.value.replace(/[^0-9.]/g, "").slice(0, 5))
  }

  const hideInput = () => {
    if (errorTextCard) {
      setCard(maxNum)
      setErrorTextCard("")
    }
    if (errorTextDk) {
      setDK(maxNum)
      setErrorTextDK("")
    }
  }

  return (
    <section
      className={cn(styles.sectioncalc, darkColor && styles.darkColor)}
      id="calculator"
      data-exclude={orderNum}
    >
      <Container>
        <div className={styles.calcHeader}>
          <h2 className={styles.head}>Рассчитайте свой заработок</h2>
        </div>
        <p className={styles.descr}>На примере карты &laquo;Халва&raquo; и денежных кредитов</p>
        <div className={styles.content}>
          <div className={styles.sliderContainer}>
            <p className={styles.sliderDescr}>Количество выданных карт</p>
            <div>
              <TextField
                className={styles.sliderSum}
                fullWidth
                onBlur={hideInput}
                onChange={handleOnChangeSumCard}
                value={card}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <Slider
              value={card}
              onChange={onChangeSpending}
              max={maxNum}
              step={1}
              classes={{
                root: styles.slider,
                track: styles.track,
                thumb: styles.thumb,
                rail: styles.rail,
              }}
            />
            {!!errorTextCard && <div className={styles.errorText}>{errorTextCard}</div>}
            <p className={styles.sliderDescr}>Количество выданных ДК</p>
            <div>
              <TextField
                className={styles.sliderSum}
                fullWidth
                onChange={handleOnChangeSumDk}
                onBlur={hideInput}
                value={dk}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <Slider
              value={dk}
              onChange={onChangeBalance}
              max={maxNum}
              step={1}
              classes={{
                root: styles.slider,
                track: styles.track,
                thumb: styles.thumb,
                rail: styles.rail,
              }}
            />
            {!!errorTextDk && <div className={styles.errorText}>{errorTextDk}</div>}
          </div>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <p className={styles.cardHead}>Ваш доход</p>
            </div>
            <div className={styles.sum}>
              {sum.toLocaleString().replace(/,/g, " ")} <span>₽</span>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
