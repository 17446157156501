// extracted by mini-css-extract-plugin
export var sectionlist = "ProductList-module--sectionlist--fxQmV";
export var head = "ProductList-module--head--VCNhE";
export var grid = "ProductList-module--grid--UIM10";
export var block = "ProductList-module--block--IV4lH";
export var imagecontent = "ProductList-module--imagecontent--uVoH4";
export var title = "ProductList-module--title--HkYuj";
export var description = "ProductList-module--description--QDcm7";
export var price = "ProductList-module--price--sMLHQ";
export var icon = "ProductList-module--icon--b8M9b";
export var upper = "ProductList-module--upper--o770Z";