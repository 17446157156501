import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, withArtDirection, GatsbyImageProps } from "gatsby-plugin-image"

const imgQuery = graphql`
  {
    mobile: file(relativePath: { eq: "Banners/product/mob.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    tablet: file(relativePath: { eq: "Banners/product/tab.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    desktop: file(relativePath: { eq: "Banners/product/desk.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    large: file(relativePath: { eq: "Banners/product/large.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

export function Img(props: Omit<GatsbyImageProps, "image">) {
  const { mobile, tablet, desktop, large } = useStaticQuery(imgQuery)
  const images = withArtDirection(getImage(large)!, [
    {
      media: "(max-width: 599.95px)",
      image: getImage(mobile)!,
    },
    {
      media: "(max-width: 959.95px)",
      image: getImage(tablet)!,
    },
    {
      media: "(max-width: 1279.95px)",
      image: getImage(desktop)!,
    },
  ])

  return <GatsbyImage {...props} image={images} />
}
