import React from "react"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { Img } from "./Img"
import { scrollToPersonalForm } from "../../../helpers/scrollToPersonalForm"
import * as styles from "./banner.module.scss"

export function Banner() {
  function handleClick() {
    scrollToPersonalForm()
    pushToDataLayer({
      event: "buttonClick",
      name: "Зарегистрироваться",
      placement: "registration partner",
    })
  }

  return (
    <section className={styles.sectionbanner}>
      <Container className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Зарабатывайте
            <br />с Совкомбанком!
          </h1>
          <p className={styles.subTitle}>
            Рекламируйте продукты банка и получайте <br />
            до 15 000 руб. за каждого клиента
          </p>
          <Button className={styles.btn} onClick={handleClick}>
            Зарегистрироваться
          </Button>
        </div>
        <Img alt="image" className={styles.img} />
      </Container>
    </section>
  )
}
