import React from "react"
import Container from "@ecom/ui/components/Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import { scrollToPersonalForm } from "../../helpers/scrollToPersonalForm"
import * as styles from "./HowWork.module.scss"

type Props = {
  titleUr?: React.ReactNode
  titleFiz?: React.ReactNode
}

export function HowWork({
  titleUr = "Для юридических лиц",
  titleFiz = "Для физических лиц",
}: Props) {
  function handleClick() {
    scrollToPersonalForm()
    pushToDataLayer({
      event: "buttonClick",
      name: "Зарегистрироваться",
      placement: "registration partner",
    })
  }

  return (
    <section className={styles.sectionWork}>
      <Container>
        <h2>Как это работает</h2>
        <div className={styles.containerfluid}>
          <div className={styles.form}>
            <div className={styles.infour}>
              <h3 className={styles.head}>{titleUr}</h3>
              <ol className={styles.numbers}>
                <li>
                  <span>1</span>Заполните форму и зарегистрируйтесь в реферальной программе
                </li>
                <li>
                  <span>2</span>Выберите продукт, который хотите рекомендовать, получите
                  индивидуальную ссылку и материалы
                </li>
                <li>
                  <span>3</span>Размещайте материалы со ссылкой на своих ресурсах и получайте деньги
                  за привлеченных клиентов
                </li>
              </ol>
              <Button className={styles.btn} onClick={handleClick}>
                Зарегистрироваться
              </Button>
              <StaticImage
                className={styles.img}
                alt="diagrama"
                src="./images/diagrama.png"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
              />
            </div>
          </div>
          <div className={styles.formtelega}>
            <div className={styles.infofiz}>
              <h3 className={styles.head}>{titleFiz}</h3>
              <p className={styles.fizcontent}>
                Регистрируйтесь в программе для физических лиц и становитесь внештатным сотрудником
                Совкомбанка
              </p>
              <StaticImage
                className={styles.telega}
                alt="telegram"
                src="./images/telega.png"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
              />
              <a
                href="https://sovcombank.ru/apply/invitefriend/"
                target="_blank"
                className={styles.url}
                rel="noreferrer"
              >
                Перейти
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
