// extracted by mini-css-extract-plugin
export var sectionWork = "HowWork-module--sectionWork--kAqXP";
export var containerfluid = "HowWork-module--containerfluid--kUpzV";
export var form = "HowWork-module--form--ThHF+";
export var formtelega = "HowWork-module--formtelega--61Zc1";
export var infour = "HowWork-module--infour--+KjMB";
export var infofiz = "HowWork-module--infofiz--i8cvl";
export var head = "HowWork-module--head--eLcTx";
export var img = "HowWork-module--img--pzK4-";
export var numbers = "HowWork-module--numbers--lgVMi";
export var url = "HowWork-module--url--K1vYn";
export var box = "HowWork-module--box--SP62u";
export var telega = "HowWork-module--telega--CC9H0";
export var btn = "HowWork-module--btn--Zr4Lf";