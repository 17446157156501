import React from "react"
import clsx from "clsx"
import { navigate } from "gatsby"
import Container from "@ecom/ui/components/Container"
import FormLanding from "@ecom/ui/components/FormLanding"
import PhoneField from "@ecom/ui/components/FormLanding/fields/PhoneField"
import EmailField from "@ecom/ui/components/FormLanding/fields/EmailField"
import AcceptmentField from "@ecom/ui/components/FormLanding/fields/AcceptmentField"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import DadataFio from "@ecom/ui/components/FormLanding/fields/DadataFields/DadataFio"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"
import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import sendRequest from "@ecom/ui/utils/sendRequest"
import { formatValuesToRequest } from "./helpers/formatToRequest"
import Acceptment from "../../fields/AcceptmentFieldUnic"
import * as styles from "./form.module.scss"
import { handleClickForm } from "../../../helpers/WatcherDL/utils/handleClickForm"
import { DLGAFormEvent } from "../../../helpers/WatcherDL/DLEvents"

export function Form() {
  function onSubmit(values: any) {
    pushToDataLayer({
      event: "GAFormEvent",
      name: "Продолжить",
      placement: "appl_halva",
      eventAction: "passportForm",
      eventCategory: "sendForm",
    })
    const formattedToReq = formatValuesToRequest(values)
    const additionalData = getAdditionalData()
    const apiUrl = `${process.env.GATSBY_APP_URL_API}/v2/app/public/referralhub`

    sendRequest(apiUrl, { ...formattedToReq, additionalData })
      .then(() => {
        pushToDataLayer({
          event: "applicationSend",
          result: "success",
        })
        DLGAFormEvent({
          eventCategory: "sendForm",
          eventAction: "personalForm",
          placement: "main",
        })

        navigate("/referall/success/")
      })
      .catch(() => {
        pushToDataLayer({
          event: "applicationSend",
          result: "error",
        })

        navigate("/referall/error/")
      })
  }

  const onChangeFields = {
    phone: () => {
      handleClickForm()
    },
    fio: () => {
      handleClickForm()
    },
    email: () => {
      handleClickForm()
    },
  }

  return (
    <section className={styles.rootform} id="form">
      <div className={styles.containerfluid}>
        <Container className={styles.container}>
          <h1 className={clsx(styles.head, "text-center")}>Оставьте заявку</h1>
          <FormLanding
            onSubmit={onSubmit}
            className={styles.newform}
            onChangeFields={onChangeFields}
          >
            <DadataFio label="ФИО" name="fio" className={styles.input} />
            <PhoneField name="phone" className={styles.input} />
            <EmailField name="email" label="E-mail" className={styles.input} />
            <AcceptmentField name="agree" className={styles.accept} />
            <Acceptment name="impartner" className={styles.accept} />
            <div className="text-center">
              <SubmitButton className={styles.btn}>Оставить заявку</SubmitButton>
            </div>
          </FormLanding>
        </Container>
      </div>
    </section>
  )
}
