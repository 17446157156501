import React from "react"
import Container from "@ecom/ui/components/Container"
import { Car, Key, Card, Money, Protect, Roll, Safe } from "./img"

import * as styles from "./ProductList.module.scss"

const ITEMS = [
  {
    title: <> Карта рассрочки «Халва»</>,
    id: 1,
    subtitle: <>Бесплатная рассрочка Кэшбэк до 10%</>,
    price: <>До 1 500 ₽</>,
    smalldesription: <>за каждую оформленную карту</>,
    image: (
      <>
        {" "}
        <Card alt="card" />
      </>
    ),
  },
  {
    title: <>Денежный кредит</>,
    id: 2,
    subtitle: <>Кредит от 6,9% с минимальным пакетом документов</>,
    price: <>До 10 000 ₽</>,
    smalldesription: <>за каждый оформленный кредит</>,
    image: (
      <>
        {" "}
        <Money alt="Money" />
      </>
    ),
  },
  {
    title: <>Рефинансирование кредитов</>,
    id: 3,
    subtitle: <>В рассрочку до 30 месяцев под 0%</>,
    price: <>До 10 000 ₽</>,
    smalldesription: <>за каждого клиента</>,
    image: (
      <>
        {" "}
        <Roll alt="roll" />
      </>
    ),
  },
  {
    title: <>Ипотека</>,
    id: 4,
    subtitle: <>Выгодные условия для покупки недвижимости</>,
    price: <>До 15 000 ₽</>,
    smalldesription: <>за каждого клиента</>,
    image: (
      <>
        {" "}
        <Key alt="key" />
      </>
    ),
  },
  {
    title: <>Автокредиты</>,
    id: 5,
    subtitle: <>В рассрочку с первоначальным взносом от 0%</>,
    price: <>До 10 000 ₽</>,
    smalldesription: <>за каждый оформленный кредит</>,
    image: (
      <>
        {" "}
        <Car alt="car" />
      </>
    ),
  },
  {
    title: <>Страховые продукты</>,
    id: 6,
    subtitle: <>ОСАГО, КАСКО и другие продукты на выгодных условиях</>,
    price: <>До 4 000 ₽</>,
    smalldesription: <>за каждого клиента</>,
    image: (
      <>
        {" "}
        <Protect alt="protect" />
      </>
    ),
  },
  {
    title: <>Вклады и накопительные счета</>,
    id: 7,
    subtitle: <>Доход до 8,6% годовых</>,
    price: <>До 1 000 ₽</>,
    smalldesription: <>за каждого клиента</>,
    image: (
      <>
        {" "}
        <Safe alt="safe" />
      </>
    ),
  },
]

export const ProductList = () => (
  <section className={styles.sectionlist}>
    <Container>
      <h2 className={styles.head}>Предлагайте продукты своим клиентам:</h2>
      <div className={styles.grid}>
        {ITEMS.map(({ title, subtitle, price, smalldesription, image, id }) => (
          <div className={styles.block} key={id}>
            <div className={styles.upper}>
              <h3 className={styles.title}>{title}</h3>
              <p className={styles.description}>{subtitle}</p>
            </div>
            <div className={styles.price}>
              <span>{price}</span>
              <p>{smalldesription}</p>
            </div>
            <div className={styles.imagecontent}>{image}</div>
          </div>
        ))}
      </div>
    </Container>
  </section>
)
