import React from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./stepProducts.module.scss"

const STEPS = [
  {
    title: <>Расчетный счет</>,
    subtitle: <>Подача заявки без визита в офис и реквизиты за 3 минуты</>,
    id: 1,
  },
  {
    title: <>Сервис оплаты «Частями»</>,
    subtitle: <>Оплата покупок 4 частями за 6 недель</>,
    id: 2,
  },
  {
    title: <>Стать партнером Халвы</>,
    subtitle: <>Дополнительный маркетинг для вашего бизнеса</>,
    id: 3,
  },
]

export const StepProducts = () => (
  <section className={styles.sectionstep}>
    <Container className={styles.container}>
      <h2 className={styles.title}>Продукты</h2>
      <p>Подключайте продукты для своего бизнеса:</p>
      <div className={styles.wrapper}>
        <ul className={styles.steps}>
          {STEPS.map(({ title, subtitle, id }) => (
            <div className={styles.stepText} key={id}>
              <p className={styles.stepTitle}>{title}</p>
              <p className={styles.stepSubtitle}>{subtitle}</p>
            </div>
          ))}
        </ul>
      </div>
    </Container>
  </section>
)
