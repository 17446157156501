import React from "react"
import Checkbox from "@ecom/ui/components/FormLanding/fields/CheckboxField"
import { makeStyles } from "@material-ui/core/styles"

const useAcceptmentStyles = makeStyles(() => ({
  labelLabel: {
    fontWeight: 300,
    fontSize: 12,
    lineHeight: 1.3,
    padding: "9px 0",
  },

  labelRoot: {
    marginRight: 0,
  },

  helperTextRoot: {
    marginTop: -7,
  },

  checkboxRoot: {
    "&$checkboxChecked": {
      color: "red",
    },
  },

  checkboxChecked: {},

  link: {
    fontWeight: "bold",
  },
}))

const Acceptment = React.memo((props: any) => {
  const classes = useAcceptmentStyles(props)

  const onChange = (e: any) => {
    props.onChange(e.target.checked)
  }

  return (
    <Checkbox
      color="primary"
      label={<>Я партнёр Халвы</>}
      {...props}
      classes={{
        labelClasses: {
          label: classes.labelLabel,
          root: classes.labelRoot,
        },
        helperTextClasses: { root: classes.helperTextRoot },
        checkboxClasses: { root: classes.checkboxRoot, checked: classes.checkboxChecked },
      }}
      onChange={onChange}
      checked={props.value}
    />
  )
})

export default Acceptment
