import React from "react"
import { graphql, PageProps } from "gatsby"
import { PageData } from "../interfaces/pageProps"
import { getPageData } from "../helpers/getPageData"
import { LayoutBase } from "../components/Layout/base"
import { Banner } from "../components/products/Banner"
import { HowWork } from "../components/HowWork"
import { StepProducts } from "../components/stepProducts"
import { ProductList } from "../components/ProductList"
import { AdvicesBlock } from "../components/advices"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { Form } from "../components/application/Form"
import { UnicHeader } from "../components/Headers/UnicHeader"
import { WhiteHalva } from "../components/Headers/UnicHeader/Logo/whiteHalva"
import { FooterProducts } from "../components/Footer/FooterProducts/FooterProducts"
import favicon from "../images/faviconnew.svg"

export default function Pageb2b({ data }: PageProps<PageData>) {
  const { seoData, ligal } = getPageData(data)

  return (
    <LayoutBase seoData={seoData} favicon={favicon}>
      <UnicHeader Logo={WhiteHalva} buttonText="Зарегистрироваться" />
      <Banner />
      <HowWork />
      <StepProducts />
      <ProductList />
      <AdvicesBlock />
      <NewCalculator />
      <Form />
      <FooterProducts>
        {ligal.shortText && (
          <span dangerouslySetInnerHTML={{ __html: ligal.shortText }} id="ligal" />
        )}
      </FooterProducts>
    </LayoutBase>
  )
}

export const query = graphql`
  query {
    pageDataJson(pageName: { eq: "referralhub" }) {
      seoData {
        title
        description
      }
    }
    admin {
      page(url: "https://go.halvacard.ru/referralhub/") {
        ligal {
          text
        }
      }
    }
  }
`
