import React from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./advicesBlock.module.scss"

const STEPS = [
  {
    title: <>Начинайте зарабатывать с себя!</>,
    subtitle: <>Оформите себе любой из предложенных продуктов банка и получите деньги</>,
    id: 1,
  },
  {
    title: <>Мы готовы сотрудничать</>,
    subtitle: (
      <>
        с CPA-сетями, финансовыми витринами, различными сайтами и блогерами. Оставьте заявку, и мы
        обсудим детали
      </>
    ),
    id: 2,
  },
  {
    title: <>Выводите деньги за каждое оформление</>,
    subtitle: <>и следите за своим прогрессом в личном кабинете</>,
    id: 3,
  },
]

export const AdvicesBlock = () => (
  <section className={styles.sectionadvices}>
    <Container className={styles.container}>
      <h2 className={styles.title}>Полезные советы</h2>
      <div className={styles.wrapper}>
        <ul className={styles.steps}>
          {STEPS.map(({ title, subtitle, id }) => (
            <div className={styles.stepText} key={id}>
              <p className={styles.stepTitle}>{title}</p>
              <p className={styles.stepSubtitle}>{subtitle}</p>
            </div>
          ))}
        </ul>
      </div>
    </Container>
  </section>
)
